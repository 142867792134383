import React, { useState, useEffect } from "react"; // Add useEffect
import "./Carousel.css";

interface CarouselProps {
  rotateX: number;
  rotateY: number;
  images1: string[];
  images2: string[];
}
const Pangaea: React.FC<CarouselProps> = ({
  images1,
  images2,
  rotateX,
  rotateY,
}) => {
  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex1((prevIndex) => (prevIndex + 1) % 3);

      setActiveIndex2((prevIndex) => (prevIndex + 1) % 3);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div
        className="imgBox tablet"
        style={{
          transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
        }}
      >
        <div className="sec06_img01">
          {images1.map((imageSrc, index) => (
            <div
              key={index}
              className={`carousel-image2 ${
                activeIndex1 === index ? "active" : ""
              }`}
              style={{ backgroundImage: `url(${imageSrc})` }}
            ></div>
          ))}
        </div>
        <div className="sec06_img02">
          {images2.map((imageSrc, index) => (
            <div
              key={index}
              className={`carousel-image ${
                activeIndex2 === index ? "active" : ""
              }`}
              style={{ backgroundImage: `url(${imageSrc})` }}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Pangaea;
