import React from "react";
import "./footer.css";

const Footer: React.FC = () => {
  const openseaUrl = "";
  const etherscanUrl = "";
  const twitterUrl = "https://twitter.com/LinkAlter";
  const discordUrl = "https://discord.gg/qtAXPaYzsz";

  return (
    <footer id="footer">
      <div className="footerInner">
        <p className="copy">ⓒ 2023 ALTERLINK All Rights Reserved</p>
        <div className="icons">
          <img
            src={process.env.PUBLIC_URL + "/img/main/ft_icon01.png"}
            alt="opensea"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/main/ft_icon02.png"}
            alt="etherscan"
          />
          <button
            onClick={() => {
              window.open(twitterUrl);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/main/ft_icon03.png"}
              alt="twitter"
            />
          </button>
          <button
            onClick={() => {
              window.open(discordUrl);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/main/ft_icon04.png"}
              alt="discord"
            />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
