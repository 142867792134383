const Mission = () => {
  return (
    <div id="section09" className="pc">
      <div className="missionTitle">
        <h2 className="font2">MISSION</h2>
        <p>A stride toward the future of Alter Link</p>
      </div>
      <div className="missionWrap">
        <div className="missionCont cont01">
          <div className="missionBox box01">
            <p className="num font2">01</p>
            <p className="title">Establishment</p>
            <p className="text">
              The establishment of
              <br />
              Alter Link’s headquarters
            </p>
          </div>
          <div className="missionBox box02">
            <p className="num font2">02</p>
            <p className="title">First open</p>
            <p className="text">
              The opening of Alter Link’s
              <br />
              first project, “Pan-gaea”.
            </p>
          </div>
          <div className="missionBox box03">
            <p className="num font2">03</p>
            <p className="title">P-Pan-gaea</p>
            <p className="text">
              Preparing to build fences around
              <br />
              the world using “P-Pan-gaea.”
            </p>
          </div>
        </div>
        {/* mission cont01 */}
        <div className="missionCont cont02">
          <div className="missionBox box04">
            <p className="num font2">04</p>
            <p className="title">Unlock</p>
            <p className="text">
              Unlocks a portal that can be
              <br />
              utilized by Alter Link NFT holders.
            </p>
          </div>
          <div className="missionBox box05">
            <p className="num font2">05</p>
            <p className="title">Air drop</p>
            <p className="text">
              Additional accessories that can be used
              <br />
              to decorate Alter Link NFTs will be airdropped.
            </p>
          </div>
          <div className="missionBox box06">
            <p className="num font2">06</p>
            <p className="title">game development</p>
            <p className="text">
              Distribution of games
              <br />
              exclusive to Alter Link NFT.
            </p>
          </div>
        </div>
        {/* mission cont03 */}
        <div className="missionCont cont03">
          <div className="missionBox box07">
            <p className="num font2">07</p>
            <p className="title">First benefit</p>
            <p className="text">
              Special benefits of Alter Link NFT for the
              <br />
              majority of community participants and
              <br />
              companions.
            </p>
          </div>
          <div className="missionBox box08">
            <p className="num font2">08</p>
            <p className="title">World as one</p>
            <p className="text">
              Forming the “Pan-gaea” community,
              <br />
              uniting the world.
            </p>
          </div>
          <div className="missionBox box09">
            <p className="num font2">06</p>
            <p className="title">Party</p>
            <p className="text">
              DTo celebrate the successful distribution of
              <br />
              Alter Link’s first project, “Pan-gaea,” the
              <br />
              NFT holder party is currently underway.
            </p>
          </div>
        </div>
        {/* mission cont03 */}
      </div>
      <div className="missionBottom">
        <p className="btText01 font2">
          <span>
            A stride toward the future of Alter Link&nbsp;&nbsp;A stride toward
            the future of Alter Link&nbsp;&nbsp;A stride toward the future of
            Alter Link
          </span>
        </p>
        <p className="btText02 font2">
          <span>
            The only P-Pangaea that connects the world &nbsp;&nbsp; The only
            P-Pangaea that connects the world &nbsp;&nbsp; The only P-Pangaea
            that connects the world &nbsp;&nbsp; The only P-Pangaea that
            connects the World
          </span>
        </p>
      </div>
    </div>
  );
};
export default Mission;
