import { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";

type CountryArea = "usa" | "ger" | "jap" | "chn" | "fra" | "twn";

const Chat = () => {
  const [activeArea, setActiveArea] = useState<CountryArea>("usa");
  const areaMessages: Record<CountryArea, string[]> = {
    usa: [
      "Hi, nice to meet you!\nThis is the town where I live.",
      "Hello, nice to meet you too.\nThe neighborhood seems to be Japanese. Are you Japanese?",
      "Hi, nice to meet you.\nWhere is that picture taken in Japan?\nI am very interested in Japanese culture.",
    ],
    ger: [
      "Hallo, nett dich kennenzulernen.\nDies ist die Stadt, in der ich lebe.",
      "Hallo, nett dich auch kennenzulernen.\nDie Nachbarschaft scheint japanisch zu sein. Bist du zufällig Japaner?",
      "Hallo, nett dich kennenzulernen.\nWo wurde dieses Bild in Japan aufgenommen?\nIch interessiere mich sehr für die japanische Kultur.",
    ],
    jap: [
      "こんにちは、初めまして。\nこれは私が住んでいる町です。",
      "こんにちは、初めまして。お会いできてうれしいです。\nこの近所は日本的な雰囲気がありますね。もしかして、あなたは日本人ですか？",
      "こんにちは、初めまして。\nその写真は日本のどこですか？\n私は日本の文化にとても興味があります。",
    ],
    chn: [
      "你好，很高兴认识你。\n这是我居住的城镇。",
      "你好，很高兴认识你。\n这个附近看起来有日本的感觉。你是不是日本人呢？",
      "你好，很高兴认识你。\n那张照片在日本的哪里？\n我对日本文化非常感兴趣",
    ],
    fra: [
      "Salut, enchanté(e) de faire votre connaissance.\nC'est la ville où j'habite.",
      "Bonjour, enchanté(e) de faire votre connaissance également.\nLe quartier semble avoir une atmosphère japonaise. Êtes-vous japonais par hasard ?",
      "Bonjour, enchanté(e) de vous rencontrer.\nOù se trouve cette photo au Japon ? Je suis très intéressé(e) par la culture japonaise.",
    ],
    twn: [
      "你好，很高興認識你。\n這是我居住的城鎮。",
      "你好，也很高興認識你。\n這個附近看起來像是日本風格。你是不是日本人呢？",
      "是嗨，很高興認識你。\n那張照片在日本的哪裡？我對日本文化非常感興趣。",
    ],
  };

  const [typingSequence, setTypingSequence] = useState<string[]>([
    areaMessages["usa"][0],
    areaMessages["usa"][1],
    areaMessages["usa"][2],
  ]);

  const handleAreaClick = (area: CountryArea) => {
    setActiveArea(area);
  };

  useEffect(() => {
    setTypingSequence([areaMessages[activeArea][0]]);
    setTypingSequence([
      areaMessages[activeArea][0],
      areaMessages[activeArea][1],
      areaMessages[activeArea][2],
    ]);
  }, [activeArea]);

  return (
    <>
      <div className="sec_inner chatWrap">
        <img
          src={process.env.PUBLIC_URL + "/img/main/main_chat_logo.png"}
          alt=""
          className="chatBg"
        />
        <div className="chatArea ">
          <p
            className={activeArea === "usa" ? "on" : ""}
            onClick={() => handleAreaClick("usa")}
          >
            United States
          </p>
          <p
            className={activeArea === "ger" ? "on" : ""}
            onClick={() => handleAreaClick("ger")}
          >
            Deutschland
          </p>
          <p
            className={activeArea === "jap" ? "on" : ""}
            onClick={() => handleAreaClick("jap")}
          >
            日本
          </p>
          <p
            className={activeArea === "chn" ? "on" : ""}
            onClick={() => handleAreaClick("chn")}
          >
            中国
          </p>
          <p
            className={activeArea === "fra" ? "on" : ""}
            onClick={() => handleAreaClick("fra")}
          >
            France
          </p>
          <p
            className={activeArea === "twn" ? "on" : ""}
            onClick={() => handleAreaClick("twn")}
          >
            Đài Loan
          </p>
        </div>
        <div className="sec7_cont">
          <div className="left">
            <div className="profile">
              <div className="profileImg">
                <img
                  src={process.env.PUBLIC_URL + "/img/main/chat_profile.png"}
                  alt=""
                  className=""
                />
              </div>
              <div className="profileCenter">
                <h3>
                  <span>Danaka</span>
                </h3>
                <p>
                  <span>Japan</span>
                </p>
              </div>
              <div className="profileBottom">
                <p className="address">
                  <span className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/copy_icon.png"}
                      alt=""
                    />
                  </span>
                  <span className="text">0x815….8814</span>
                </p>
                <p className="mail">
                  <span className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/mail_icon.png"}
                      alt=""
                    />
                  </span>
                  <span className="text">danaka@gmail.com</span>
                </p>
                <div className="button">
                  <span>Sign out</span>
                </div>
              </div>
            </div>
            {/* profile */}
            <div className="smallProfileWrap">
              <div className="smallProfile smallPf01">
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile.png"}
                    alt=""
                  />
                </div>

                <p>
                  <span>Danaka</span>
                </p>
              </div>
              <div className="smallProfile smallPf02">
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile2.png"}
                    alt=""
                  />
                </div>

                <p>
                  <span>Nico</span>
                </p>
              </div>
              <div className="smallProfile smallPf03">
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile3.png"}
                    alt=""
                  />
                </div>

                <p>
                  <span>Nicooon</span>
                </p>
              </div>
              <div className="smallProfile smallPf04">
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile.png"}
                    alt=""
                  />
                </div>

                <p>
                  <span>Boonto</span>
                </p>
              </div>
            </div>
          </div>
          <div className={`right ${activeArea ? activeArea : ""}`}>
            {/* chat01 */}
            <div className="chat01 chatReceive chatCont">
              <div className="chatProfile">
                <div className="info">
                  <p className="name">
                    <span>Nico</span>
                  </p>
                  <p className="time">11:30PM</p>
                </div>
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile2.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className="chatSub">
                <img
                  src={process.env.PUBLIC_URL + "/img/main/chatImg01.png"}
                  alt=""
                  className="chatImg"
                />
                <p>
                  <TypeAnimation
                    key={typingSequence[0]}
                    cursor={true}
                    sequence={[typingSequence[0]]}
                    wrapper="span"
                    speed={90}
                  />
                </p>
              </div>
            </div>
            {/* chat01 */}
            {/* chat02 */}
            <div className="chat02 chatSend chatCont">
              <div className="chatProfile">
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile.png"}
                    alt=""
                  />
                </div>
                <div className="info">
                  <p className="name">
                    <span>Danaka</span>
                  </p>
                  <p className="time">11:35PM</p>
                </div>
              </div>
              <div className="chatSub">
                <p>
                  <TypeAnimation
                    key={typingSequence[1]}
                    cursor={true}
                    sequence={[typingSequence[1]]}
                    wrapper="span"
                    speed={90}
                  />
                </p>
              </div>
            </div>
            {/* chat02 */}
            {/* chat03 */}
            <div className="chat03 chatReceive chatCont">
              <div className="chatProfile">
                <div className="info">
                  <p className="name">
                    <span>Nicooon</span>
                  </p>
                  <p className="time">11:40PM</p>
                </div>
                <div className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/chat_profile3.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className="chatSub">
                <p>
                  <TypeAnimation
                    key={typingSequence[2]}
                    cursor={true}
                    sequence={[typingSequence[2]]}
                    wrapper="span"
                    speed={90}
                  />
                </p>
              </div>
            </div>
            {/* chat03 */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
