import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.css";

const Header: React.FC = () => {
  // 햄버거메뉴
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const handleIconClick = () => {
    setIsActive(!isActive);
  };
  return (
    <header id="header">
      <div className="header_inner sec_inner">
        <Link to="/" className="mintBtn">
          <span></span>
        </Link>

        <div className="icon" onClick={handleIconClick}>
          <div className={`bar one ${isActive ? "active" : "notActive"}`}></div>
          <div className={`bar two ${isActive ? "active" : "notActive"}`}></div>
          <div
            className={`bar three ${isActive ? "active" : "notActive"}`}
          ></div>
        </div>
      </div>

      <nav className={`navi  ${isActive ? "active" : "notActive"}`}>
        <ul>
          <li className={location.pathname === "/" ? "activeLink" : ""}>
            <Link to="/">Home</Link>
          </li>
          <li className={location.pathname === "/minting" ? "activeLink" : ""}>
            <Link to="/">Minting</Link>
          </li>
          <li className={location.pathname === "/pangaea" ? "activeLink" : ""}>
            <Link to="/">P-Pangaea</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
