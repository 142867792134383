import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./main.css";
import "./mediaQuery.css";
import Plx from "react-plx";
import { ReactComponent as SVG } from "./svg.svg";
import { ReactComponent as SVG2 } from "./svg2.svg";
import { ReactComponent as SVG3 } from "./svg3.svg";

import CountUp from "react-countup";
import { useDencrypt } from "use-dencrypt-effect";
import CountDown from "./CountDown";
import Mission from "./Mission";
import MissionM from "./Mission_m";
import Chat from "./Chat";
import Pangaea from "./Pangaea";

import { ReactComponent as LineSvg } from "./line.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import * as VFX from "react-vfx";

import { IoIosArrowDown } from "react-icons/io";

const Main: React.FC = () => {
  //메인글씨
  const [value, setValue] = useDencrypt(" ");

  // scroll 확인
  // const [position, setPosition] = useState(0);
  // function onScroll() {
  //   setPosition(window.scrollY);
  // }
  //scroll event (force)
  const forceRef = useRef<HTMLDivElement>(null); // Ref 객체 생성
  const [scrollPercent, setScrollPercent] = useState(0);

  //숫자 증가
  const numRef = useRef<HTMLDivElement>(null); // Ref 객체 생성
  const [countValue, setCountValue] = useState(0);

  // mousemove event
  let x = 0;
  let y = 0;
  let mx = 0;
  let my = 0;

  const handleMouseMove = (e: MouseEvent) => {
    x = e.clientX - window.innerWidth / 2;
    y = e.clientY - window.innerHeight / 2;
  };

  const [pos, setPos] = useState({ x: 0, y: 0 }); // 이미지 위치
  const loop = () => {
    //const speed = 0.009;
    const speed = 0.009;

    mx += (x - mx) * speed;
    my += (y - my) * speed;

    window.requestAnimationFrame(loop);
    setPos({ x: mx, y: my });
  };

  //판게아 마우스 move
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  const handleMouseMove2 = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const xRotation = (y / rect.height) * 20 - 7.5;
    const yRotation = (x / rect.width) * -15 + 7.5;

    setRotateX(xRotation);
    setRotateY(yRotation);
  };

  // 스크롤시 addclass
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const ref5 = useRef<HTMLDivElement>(null);
  const ref6 = useRef<HTMLDivElement>(null);
  const ref7 = useRef<HTMLDivElement>(null);
  const ref8 = useRef<HTMLDivElement>(null);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);

  const checkVisibility = (
    ref: React.RefObject<HTMLDivElement>,
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!ref.current) return;

    const divTopPosition = ref.current.getBoundingClientRect().top;

    // div의 상단이 화면의 상단에 도달했을 때
    if (divTopPosition <= 700) {
      setter(true);
    } else {
      setter(false);
    }
  };

  const handleScroll2 = () => {
    checkVisibility(ref1, setActive1);
    checkVisibility(ref2, setActive2);
    checkVisibility(ref3, setActive3);
    checkVisibility(ref4, setActive4);
    checkVisibility(ref5, setActive5);
    checkVisibility(ref6, setActive6);
    checkVisibility(ref7, setActive7);
    checkVisibility(ref8, setActive8);
  };
  useEffect(() => {
    //메인글씨
    setValue("Are you ready to link?");
    const handleScroll = () => {
      if (!forceRef.current) return;

      const sectionTop = forceRef.current.getBoundingClientRect().top;
      const sectionHeight = forceRef.current.offsetHeight;
      const viewportHeight = window.innerHeight;

      // 이제 sectionTop은 0 (상단에 도착)부터 -sectionHeight (하단에 도착)까지의 범위를 가집니다.
      // 이 값을 양수로 변환하고, sectionHeight에 대한 비율을 계산하여 스크롤 퍼센트를 얻습니다.
      const scrollPosition = -sectionTop;
      const scrollPercent =
        (scrollPosition / (sectionHeight - viewportHeight)) * 100;

      setScrollPercent(scrollPercent);
      //숫자
      if (
        numRef.current !== null &&
        numRef.current.getBoundingClientRect().top < window.innerHeight - 200
      ) {
        setCountValue(9000);
      } else {
        setCountValue(0);
      }
    };

    //mousemove event
    window.addEventListener("mousemove", handleMouseMove);

    //★ 메인에서 벗어나면 중단되게 해야함
    // 반응형 (1280이상에서만 작동하도록)
    if (window.innerWidth > 1280) {
      loop();
    } else {
    }

    // scroll event
    window.addEventListener("mousemove", handleMouseMove);
    // window.addEventListener("scroll", onScroll);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll2);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      // window.removeEventListener("scroll", onScroll);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  //faq
  const [faqActive, setFaqActive] = useState<string | null>(null);

  const faqToggle = (id: string) => {
    if (faqActive === id) {
      setFaqActive(null); // 이미 활성화된 항목을 다시 클릭하면 토글
    } else {
      setFaqActive(id);
    }
  };

  // section03_m 슬라이드
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  // 판게아 모바일 슬라이드
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    appendDots: (dots: any) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: "dots_custom mobile",
  };

  // section04 슬라이드
  const settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  // 메일 연동
  const sendMail = "mailto:alterlink19@gmail.com";

  const mailButtonClick = () => {
    window.open(sendMail);
  };

  return (
    <>
      <div id="contentWrap">
        <div id="main">
          <div className="blackBg2"></div>
          <Plx
            className="blackBg"
            parallaxData={[
              {
                start: 0,
                end: 650,
                properties: [
                  {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                  },
                ],
              },
            ]}
          />

          <div className="img_wrap">
            <div className="wallBg"></div>
            <img
              src={process.env.PUBLIC_URL + "/img/main/wall.png"}
              alt=""
              className="wall"
            />
            <img
              style={{
                transform: `translate(${pos.x / 10}px, ${pos.y / 10}px)`,
              }}
              src={process.env.PUBLIC_URL + "/img/main/move_img.png"}
              alt=""
              className="move_img"
            />
          </div>
          <div id="section01">
            <div className="mainText">
              <h2 className="font3">{value}</h2>
              <VFX.VFXProvider zIndex={10}>
                <h1>
                  <VFX.VFXImg
                    src={process.env.PUBLIC_URL + "/img/main/logo2.png"}
                    alt="AlterLink"
                    shader="rgbShift"
                  />
                </h1>
              </VFX.VFXProvider>

              <p className="mobile sec_inner">
                <span>“AlterLink”</span> is a compound word formed by combining
                <span>“Alter”</span> and <span>“Link”</span>, <br />
                which signifies connecting (Link) with the other side (Alter) of
                oneself. <br />
                It represents the focus on one’s inner voice and the creation of
                a symbol to remember its essence.
              </p>
            </div>
          </div>
          <div id="section02">
            <p ref={ref1} className={active1 ? "On" : ""}>
              <span className="scrollSpan t1">
                <span className="bold">“AlterLink”</span> is a compound word
                formed by combining
              </span>
              <br />
              <span className="scrollSpan t2">
                <span className="bold">“Alter”</span> and{" "}
                <span className="bold">“Link”</span>, which signifies connecting
                (Link) with the other
              </span>
              <br />
              <span className="scrollSpan t3">
                side (Alter) of oneself. It represents the focus on one’s inner
                voice and
              </span>
              <br />
              <span className="scrollSpan t4">
                the creation of a symbol to remember its essence.
              </span>
            </p>
          </div>
        </div>
        <div
          id="section03"
          ref={forceRef}
          className={`forceWrap 
        ${scrollPercent <= 33 && scrollPercent > 0 ? "atman" : ""}
        ${scrollPercent > 33 ? "looper" : ""}
        ${scrollPercent > 66 ? "hide" : ""}
      `}
        >
          {/* 아트만 루퍼 하이드*/}
          <div className="forceWrap">
            <img
              src={process.env.PUBLIC_URL + "/img/main/forceBg.png"}
              alt=""
              className="force_bg"
            />
            <div className="text_wrap">
              <SVG />
            </div>

            {/* atman */}
            <div className="force atman height100">
              <div
                className={`peopleImg ${scrollPercent > 5 ? "active" : ""}
             ${scrollPercent > 33 ? "remove" : ""}`}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/main/atman_people.png"}
                  alt=""
                  className="people"
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/img/main/atman_people_shadow.png"
                  }
                  alt=""
                  className="shadow"
                />
              </div>
              <div
                className={`storyWrap ${scrollPercent > 5 ? "active" : ""}
            ${scrollPercent > 33 ? "remove" : ""}
            `}
              >
                <div className="circle"></div>
                <div className="lineWrap">
                  <div className="line"></div>
                </div>
                <div className="line2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/story_line.png"}
                    alt=""
                  />
                </div>
                <div className="box cf">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine right"
                  />
                  <h3>
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/atman_logo.png"}
                      alt="ATMAN"
                    />
                  </h3>
                  <div className="txt">
                    <h4>
                      <span>Alter</span>
                    </h4>
                    <p>
                      <span>
                        Ability to adapt to a rapidly changing environment.
                        <br />
                        He lives by concealing what he likes and wants to do,
                        <br />
                        conscious of society’s scrutiny.
                      </span>
                    </p>
                  </div>
                  <div className="txt">
                    <h4>
                      <span>Story</span>
                    </h4>
                    <p>
                      <span>
                        They grow up dreaming of pursuing a career in a field
                        they enjoy.
                        <br />
                        Some belong to the government and aspire to attain
                        stable and
                        <br />
                        prestigious positions. There are also Atmans who join
                        forces with loopers in pursuit of their own freedom and
                        dreams.
                      </span>
                    </p>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine"
                  />
                </div>
              </div>
            </div>
            {/* atman */}

            {/* looper */}
            <div className="force looper height100">
              <div
                className={`peopleImg ${scrollPercent > 33 ? "peopleOn" : ""}
              ${scrollPercent > 33 ? "active" : ""}
             ${scrollPercent > 66 ? "remove" : ""}`}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/main/looper_people.png"}
                  alt=""
                  className="people"
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/main/looper_people_shadow.png"
                  }
                  alt=""
                  className="shadow"
                />
              </div>
              <div
                className={`storyWrap ${scrollPercent > 33 ? "active" : ""}
            ${scrollPercent > 66 ? "remove" : ""}
            `}
              >
                <div className="circle"></div>
                <div className="lineWrap">
                  <div className="line"></div>
                </div>
                <div className="line2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/story_line.png"}
                    alt=""
                  />
                </div>
                <div className="box cf">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine right"
                  />
                  <h3>
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/looper_logo.png"}
                      alt="LOOPER"
                    />
                  </h3>
                  <div className="txt">
                    <h4>
                      <span>Alter</span>
                    </h4>
                    <p>
                      <span>
                        Forces that value their freedom and instincts.
                        <br />
                        Self-centered, but concerned about what you like and
                        value.
                        <br />
                        He has a non-resistant personality.
                      </span>
                    </p>
                  </div>
                  <div className="txt">
                    <h4>
                      <span>Story</span>
                    </h4>
                    <p>
                      <span>
                        They dream of their own paradise, free from government
                        control.
                        <br />
                        Self-centered, yet living in harmony with like-minded
                        individuals.
                        <br />
                        They are a rebel faction against the government and also
                        <br />a force opposing Hyde.
                      </span>
                    </p>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine"
                  />
                </div>
              </div>
            </div>
            {/* looper */}
            {/* hide */}
            <div className="force hide height100">
              <div
                className={`peopleImg ${scrollPercent > 66 ? "peopleOn" : ""}
              ${scrollPercent > 66 ? "active" : ""}`}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/main/hide_people.png"}
                  alt=""
                  className="people"
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/img/main/hide_people_shadow.png"
                  }
                  alt=""
                  className="shadow"
                />
              </div>
              <div
                className={`storyWrap ${scrollPercent > 66 ? "active" : ""}`}
              >
                <div className="circle"></div>
                <div className="lineWrap">
                  <div className="line"></div>
                </div>
                <div className="line2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/story_line.png"}
                    alt=""
                  />
                </div>
                <div className="box cf">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine right"
                  />
                  <h3>
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/hide_logo.png"}
                      alt="HIDE"
                    />
                  </h3>
                  <div className="txt">
                    <h4>
                      <span>Alter</span>
                    </h4>
                    <p>
                      <span>
                        The forces that covered their own flaws with machines.
                        <br />
                        Develop your own weaknesses to become stronger.
                        <br />
                        He is law-abiding, tough, and possesses a cool-headed
                        personality.
                      </span>
                    </p>
                  </div>
                  <div className="txt">
                    <h4>
                      <span>Story</span>
                    </h4>
                    <p>
                      <span>
                        If you enlist in the government’s army,
                        <br />
                        You can make yourself stronger with a better machine,
                        and
                        <br />
                        you can achieve a rise in status. Its main task is to
                        wipe out
                        <br />
                        criminals who do not comply with control.
                      </span>
                    </p>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/storyBox_line.png"}
                    alt=""
                    className="storyLine"
                  />
                </div>
              </div>
            </div>
            {/* looper */}
          </div>
        </div>
        {/* section03 */}

        {/* section03_m 시작 */}
        <div id="section03_m">
          <div className="sec_inner">
            <img
              className="forceBg tablet2"
              src={process.env.PUBLIC_URL + "/img/main/forceBg_t.png"}
              alt=""
            />
            <img
              className="forceBg mobile"
              src={process.env.PUBLIC_URL + "/img/main/forceBg_m.png"}
              alt=""
            />
            <Slider {...settings}>
              <div className="atman">
                <div className="imgWrap">
                  <SVG2 className="tablet2 1280Block" />
                  <SVG3 className="mobileOnly" />
                  <div className="peopleImg">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/main/atman_people.png"
                      }
                      alt=""
                      className="people"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/main/atman_people_shadow.png"
                      }
                      alt=""
                      className="shadow"
                    />
                  </div>
                </div>
                <h3>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/atman_logo.png"}
                    alt="ATMAN"
                  />
                </h3>
                <div className="detailText">
                  <div>
                    <h4>Alter</h4>
                    <p>
                      Forces that value their freedom and instincts.
                      <br />
                      Self-centered, but concerned about what you like and
                      value.
                      <br />
                      He has a non-resistant personality.
                    </p>
                  </div>
                  <div>
                    <h4>Story</h4>
                    <p>
                      They dream of their own paradise, free from government
                      control.
                      <br />
                      Self-centered, yet living in harmony with like-minded
                      individuals.
                      <br />
                      They are a rebel faction against the government and also a
                      force opposing Hyde.
                    </p>
                  </div>
                </div>
              </div>
              {/* atman */}
              <div className="looper">
                <div className="imgWrap">
                  <SVG2 className="tablet2 1280Block" />
                  <SVG3 className="mobileOnly" />
                  <div className="peopleImg">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/main/looper_people.png"
                      }
                      alt=""
                      className="people"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/main/looper_people_shadow.png"
                      }
                      alt=""
                      className="shadow"
                    />
                  </div>
                </div>
                <h3>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/looper_logo.png"}
                    alt="LOOPER"
                  />
                </h3>
                <div className="detailText">
                  <div>
                    <h4>Alter</h4>
                    <p>
                      A force that adapts to rapidly changing environments.
                      <br />
                      They live hiding what they really like and want to do,
                      conscious of society’s gaze
                    </p>
                  </div>
                  <div>
                    <h4>Story</h4>
                    <p>
                      Belong to the government and dream of a stable and high
                      position, There are also Atmans who go over to the power
                      of loopers for their own freedom and dreams.
                    </p>
                  </div>
                </div>
              </div>
              {/* looper */}
              <div className="hide">
                <div className="imgWrap">
                  <SVG2 className="tablet2 1280Block" />
                  <SVG3 className="mobileOnly" />
                  <div className="peopleImg">
                    <img
                      src={process.env.PUBLIC_URL + "/img/main/hide_people.png"}
                      alt=""
                      className="people"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/main/hide_people_shadow.png"
                      }
                      alt=""
                      className="shadow"
                    />
                  </div>
                </div>
                <h3>
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/hide_logo.png"}
                    alt="HIDE"
                  />
                </h3>
                <div className="detailText">
                  <div>
                    <h4>Alter</h4>
                    <p>
                      The forces that covered their own flaws with machines.
                      <br />
                      Develop your own weaknesses to become stronger.
                      <br />
                      He is law-abiding, tough, and possesses a cool-headed
                      personality.
                    </p>
                  </div>
                  <div>
                    <h4>Story</h4>
                    <p>
                      If you enlist in the government’s army,
                      <br />
                      You can make yourself stronger with a better machine, and
                      you can achieve a rise in status. Its main task is to wipe
                      out criminals who do not comply with control.
                    </p>
                  </div>
                </div>
              </div>
              {/* hide */}
            </Slider>
          </div>
        </div>
        {/* section03_m 끝 */}

        <div id="section04" ref={ref2} className={active2 ? "On" : ""}>
          <div className="sec_inner">
            <img
              src={process.env.PUBLIC_URL + "/img/main/sec04_top_line.png"}
              alt=""
              className="pc"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/main/sec04_top_line-1280.png"}
              alt=""
              className="tablet"
            />
            <div ref={numRef} className="text_wrap">
              <h2 className="num">
                <CountUp start={0} end={countValue} duration={1} />
              </h2>
              <h3 ref={ref2} className={active2 ? "On" : ""}>
                <span>
                  Among the three different powers, choose
                  <br />
                  the one that is right for you from the 9000 collections.
                </span>
              </h3>
              <p>
                <span className="scrollText">
                  <span className="forceName">“Hide”</span>
                  <span className="txt">
                    wants to be proud of his strength.
                  </span>
                  <br />
                  <span className="forceName">“looper”</span>
                  <span className="txt">
                    who yearns for freedom and desires a life of freedom.
                  </span>
                  <br />
                  <span className="forceName">“Atman”</span>
                  <span className="txt"> agonizes over making choices.</span>
                  <br />
                  <span className="txt">
                    able to decide what he wants Link with the faction that
                    suits you.
                  </span>
                </span>
              </p>
            </div>
            <div className="mobileOnly section04_m_slide">
              <Slider {...settings3}>
                <div className="slideImg translate">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img01.png"}
                    alt=""
                    className="img01"
                  />
                </div>
                <div className="slideImg">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img02.png"}
                    alt=""
                    className="img02"
                  />
                </div>
                <div className="slideImg translate">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img03.png"}
                    alt=""
                    className="img03"
                  />
                </div>
                <div className="slideImg">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img04.png"}
                    alt=""
                    className="img04"
                  />
                </div>
                <div className="slideImg translate">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img05.png"}
                    alt=""
                    className="img05"
                  />
                </div>
                <div className="slideImg">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec04_img06.png"}
                    alt=""
                    className="img06"
                  />
                </div>
              </Slider>
            </div>
          </div>
          <div className="img_wrap">
            <div className="imgBox imgOne">
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img01.png"}
                alt=""
                className="img01"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img04.png"}
                alt=""
                className="img04"
              />
            </div>
            <div className="imgBox imgTwo">
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img02.png"}
                alt=""
                className="img02"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img05.png"}
                alt=""
                className="img05"
              />
            </div>
            <div className="imgBox imgThree">
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img03.png"}
                alt=""
                className="img03"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/main/sec04_img06.png"}
                alt=""
                className="img06"
              />
            </div>
          </div>
          {/* section04 imagWrap pc */}
        </div>
        {/* section04 */}

        <div id="section05" className="height100">
          <div className="sect_bgText font2">live minting</div>
          <div className="sec_inner">
            <div ref={ref3} className={`${active3 ? "On" : ""} sec5_title`}>
              <h2 className="font2">Joining AlterLink</h2>
              <p>
                A collection of 9,000. Link to the faction that suits
                <br />
                you among the three factions.
              </p>
            </div>
            <div className="sec5_time">
              <CountDown />
            </div>
          </div>
        </div>
        {/* section05 */}
        {/* section06 판게아 프로젝트 시작*/}

        <div id="section06" onMouseMove={handleMouseMove2}>
          <div
            ref={ref4}
            className={`around_title sec_inner font2 ${active4 ? "On" : ""}`}
          >
            <h2>
              <span>Connect</span>
            </h2>
            <p>
              <span>
                P-Pangaea is a vast network that unites everyone.
                <br />
                Possess the "Alter Link NFT" and be the
                <br />
                first to experience the advantages of P-Pangaea.
              </span>
            </p>
          </div>
          <div className={`sec_inner cf font2`}>
            <h3 ref={ref5} className={`bigTxt ${active5 ? "On" : ""}`}>
              <span className=" bigTxt01 lineTxt">PROJECT</span>
              <span className=" bigTxt02">PANGAEA</span>
              <span className=" bigTxt03 lineTxt">CONNECT</span>
            </h3>
            <Pangaea
              rotateX={rotateX}
              rotateY={rotateY}
              images1={[
                `${process.env.PUBLIC_URL}/img/main/sec06_img1-1.png`,
                `${process.env.PUBLIC_URL}/img/main/sec06_img1-2.png`,
                `${process.env.PUBLIC_URL}/img/main/sec06_img1-3.png`,
              ]}
              images2={[
                `${process.env.PUBLIC_URL}/img/main/sec06_img2-1.png`,
                `${process.env.PUBLIC_URL}/img/main/sec06_img2-2.png`,
                `${process.env.PUBLIC_URL}/img/main/sec06_img2-3.png`,
              ]}
            />

            <Slider {...settings2} className="mobileOnly">
              <div className="atman m_imgBox">
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img1-1.png"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img2-1.png"}
                  alt=""
                />
              </div>
              <div className="looper m_imgBox">
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img1-2.png"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img2-2.png"}
                  alt=""
                />
              </div>
              <div className="hide m_imgBox">
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img1-3.png"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/main/sec06_img2-3.png"}
                  alt=""
                />
              </div>
            </Slider>
          </div>
        </div>
        {/* section06 판게아 프로젝트 끝*/}
        <div id="section07">
          <div
            ref={ref6}
            className={`around_title cf sec_inner font2 ${active6 ? "On" : ""}`}
          >
            <h2>
              <span>Communication</span>
            </h2>
            <p>
              <span>
                P-Pangaea is a large-scale project that connects everyone,
                <br />
                including the “Alter Link NFT” owners.
                <br />
                Own the “Alter Link NFT” and join P-Pangaea.
              </span>
            </p>
          </div>
          <Chat />
        </div>
        {/* section07 */}
        <div
          id="section08"
          ref={ref7}
          className={`bigTxt ${active7 ? "On" : ""}`}
        >
          <div className="sec_inner">
            <div className="sec8_textWrap">
              <h2 className="sec8_title">
                <span>
                  The only
                  <br />
                  P-Pangaea
                  <br />
                  that connects
                  <br />
                  the world
                </span>
              </h2>
              <div className="sec8_btn">
                <p className="text">WEAR NOW</p>
                <p className="arrow">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec8_arrow.png"}
                    alt=""
                  />
                </p>
                <p className="img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/sec8_btn_img.png"}
                    alt=""
                  />
                </p>
              </div>
            </div>
            <div className="svgWrap">
              <LineSvg />
              <div className="areaNames">
                <p className="ger">
                  <span>Deutschland</span>
                </p>
                <p className="fra">
                  <span>France</span>
                </p>

                <p className="areas2">
                  <span className="jpn">日本</span>
                  <br />
                  <span className="chn">中国</span>
                  <br />
                  <span className="twn">Đài Loan</span>
                </p>

                <p className="kor">
                  <span>한국</span>
                </p>
                <p className="areas">
                  <span className="area01">Deutschland</span>
                  <br />
                  <span className="area02">United States</span>
                  <br />
                  <span className="area03">France</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* section08 */}
        <div ref={ref8} className={` ${active8 ? "On" : ""}`}>
          <Mission />
        </div>
        <MissionM />
        {/* section09 */}
        <div id="section10">
          <div className="sec_inner">
            <div className="sec10_cont">
              <div className="left">
                <h2 className="font2">
                  <span>MADE IN</span>
                  <br />
                  ALTER LINK
                </h2>
                <img
                  src={process.env.PUBLIC_URL + "/img/main/faqIcon.png"}
                  alt=""
                  className="faqIcon"
                />
              </div>
              <div className="right">
                <h2 className="font2">
                  FAQ
                  <br />
                  <span>ABOUT</span>
                </h2>
                <div className="faqList">
                  <FAQItem
                    id="faq01"
                    title={faqTitle.faq01}
                    onToggle={faqToggle}
                    isActive={faqActive === "faq01"}
                    content={faqContent.faq01}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/img/main/faq_img.jpg"}
                    alt=""
                    className="faqImg"
                  />
                  <FAQItem
                    id="faq02"
                    title={faqTitle.faq02}
                    onToggle={faqToggle}
                    isActive={faqActive === "faq02"}
                    content={faqContent.faq02}
                  />
                  <FAQItem
                    id="faq03"
                    title={faqTitle.faq03}
                    onToggle={faqToggle}
                    isActive={faqActive === "faq03"}
                    content={faqContent.faq04}
                  />
                  <FAQItem
                    id="faq04"
                    title={faqTitle.faq04}
                    onToggle={faqToggle}
                    isActive={faqActive === "faq04"}
                    content={faqContent.faq04}
                  />
                </div>
                {/* faqList */}
              </div>
            </div>
            <div className="contact">
              <h4 className="font2">contact us for</h4>
              <div className="btns">
                <div className="btn">
                  <button onClick={mailButtonClick}>
                    Business<span className="arrow"></span>
                  </button>
                </div>
                <div className="btn">
                  <button onClick={mailButtonClick}>
                    Partnership<span className="arrow"></span>
                  </button>
                </div>
                <div className="btn">
                  <Link to="/">
                    Live minting<span className="arrow"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type FAQItemProps = {
  id: string;
  title: string;
  content: string;
  onToggle: (id: string) => void;
  isActive: boolean;
};

const faqTitle = {
  faq01: "What is AlterLink?  ",
  faq02: "What is the future of AlterLink?",
  faq03:
    "Is there a difference in the usefulness of<br />NFTs depending on the characteristics of NFTs held?",
  faq04: "When can AlterLink’s “Pan-gaea” be used?",
};

const faqContent = {
  faq01:
    "AlterLink is a collection of 9,000 Non-Fungible Tokens (NFTs) with three <br /> distinct factions, each representing a different personality. These NFTs can reflect <br /> the owner’s individuality or take on the appearance of another owner. Through<br />P-Pangaea within AlterLink, users can engage in communication with like-minded<br />individuals who share similar personalities and values.",
  faq02:
    "AlterLink’s P-Pangaea is an enormous and remarkable<br />initiative that seeks to unite the world. It enables smooth communication<br />between people from different countries in their native languages,<br />fostering empathy and friendship. P-Pangaea within AlterLink will expand with<br />news, lore, and numerous surprises. Naturally, AlterLink NFT holders can support<br />this exciting new journey that we are embarking on together.<br />They will be the first to receive updates and reap the benefits of this expansion.",
  faq03:
    "No. All AlterLink NFTs offered in the public sale provide the same<br />benefits and usefulness. The only distinction lies in the secondary market value,<br />which can vary based on the rarity of specific traits.",
  faq04:
    "“Pan-gaea” by AlterLink Whitelisted individuals who own AlterLink NFTs can participate<br />in the beta testing. Furthermore, after performing the first revival following the minting<br />of the AlterLink NFT, NFT owners who have completed the revival process can<br />experience “Pan-gaea” through AlterLink’s homepage.",
};

const FAQItem: React.FC<FAQItemProps> = ({
  id,
  title,
  content,
  onToggle,
  isActive,
}) => {
  return (
    <div className={`faqCont ${id} ${isActive ? "active" : ""}`}>
      <h3>
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
        <button onClick={() => onToggle(id)}>
          <IoIosArrowDown />
        </button>
      </h3>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>{" "}
    </div>
  );
};

export default Main;
